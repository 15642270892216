// extracted by mini-css-extract-plugin
export var CheerrightBoxBorder = "KeyChalanges-module--CheerrightBoxBorder--4e263";
export var aiIcon = "KeyChalanges-module--ai-icon--30702";
export var atomicBg = "KeyChalanges-module--atomicBg--efd6b";
export var challengeContent = "KeyChalanges-module--challengeContent--051bd";
export var challengeRow = "KeyChalanges-module--challengeRow--ed630";
export var challengesGrid = "KeyChalanges-module--challengesGrid--db448";
export var cheerBg = "KeyChalanges-module--cheerBg--48b6d";
export var cheerleftBoxBorder = "KeyChalanges-module--cheerleftBoxBorder--12ccd";
export var connectingLine = "KeyChalanges-module--connectingLine--621f2";
export var content = "KeyChalanges-module--content--32722";
export var graphIcon = "KeyChalanges-module--graph-icon--d69a2";
export var icon = "KeyChalanges-module--icon--abaca";
export var iconWrapper = "KeyChalanges-module--iconWrapper--55e12";
export var keyChallengesContainer = "KeyChalanges-module--keyChallengesContainer--508c6";
export var leftBox = "KeyChalanges-module--leftBox--90018";
export var leftBoxBorder = "KeyChalanges-module--leftBoxBorder--076da";
export var ocrBg = "KeyChalanges-module--ocrBg--c22b0";
export var ocrBgPd = "KeyChalanges-module--ocrBgPd--e3220";
export var rightBox = "KeyChalanges-module--rightBox--5dc4a";
export var rightBoxBorder = "KeyChalanges-module--rightBoxBorder--089ed";
export var solutionContent = "KeyChalanges-module--solutionContent--67934";
export var title = "KeyChalanges-module--title--c208b";
export var titleWrapper = "KeyChalanges-module--titleWrapper--dc6b6";
export var webglIcon = "KeyChalanges-module--webgl-icon--5dbe9";