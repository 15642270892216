import React from "react"
import Container from "react-bootstrap/Container"
import * as styles from "./workflowpredicto.module.scss"

function Workflowpredicto({ strapiData,predicto }) {
  return (
    <div className={styles.predictoContainer}>
      <Container>
        <h2
          className={styles.workflowPredictoHeading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />

        {/* Predicto banner - visible only on desktop */}
        <div className={styles.desktopOnly}>
          <img
            src={
              predicto
                ? "https://invozone-backend.s3.us-east-1.amazonaws.com/predicto_desktop_ad12b6ea59.webp"
                : "https://invozone-backend.s3.us-east-1.amazonaws.com/ai_hologram_de31b81043.webp"
            }
            alt="predicto"
          />
        </div>

        <div className={styles.mobileTabletOnly}>
          <div className={styles.workflowGridContainerMobile}>
            <img
              src={
                predicto
                  ? "https://invozone-backend.s3.us-east-1.amazonaws.com/predicto_mobile_7e5a4514fc.webp"
                  : "https://invozone-backend.s3.us-east-1.amazonaws.com/aihiologram_desktop_7a047443fe.webp"
              }
              alt="predicto"
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Workflowpredicto
