// extracted by mini-css-extract-plugin
export var backgroundImg = "Banner1-module--backgroundImg--6c17f";
export var bannerCurve = "Banner1-module--bannerCurve--26a40";
export var bannerHeading = "Banner1-module--bannerHeading--d8da3";
export var bannerText = "Banner1-module--bannerText--6526f";
export var dedicated = "Banner1-module--dedicated--cb0b9";
export var dedicatedBanner = "Banner1-module--dedicatedBanner--b0d39";
export var dedicatedHeadingStatic = "Banner1-module--dedicatedHeadingStatic--271b1";
export var desktopImage = "Banner1-module--desktopImage--80659";
export var headingIntro = "Banner1-module--headingIntro--8f190";
export var innerDedicated = "Banner1-module--innerDedicated--7ff81";
export var mobileImage = "Banner1-module--mobileImage--776c8";
export var paragraphAi = "Banner1-module--paragraphAi--f9c4c";