import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner1.module.scss"

const BannerSlider = ({ strapiData, predicto }) => {
  return (
    <div className={styles.dedicatedBanner}>
      <div className={styles.bannerText}>
        <Container>
          <Row>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={3}
              className={styles.headingContainer}
            >
              <h1
                className={styles.headingIntro}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={9}
              className={styles.headingContainer}
            >
              <p
                className={styles.paragraphAi}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container className={styles.innerDedicated}>
        <div className={styles.dedicated}>
          <img
            src={
              predicto
                ? "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557149_fc9905ef04.webp"
                : "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557277_12740c72b3.webp"
            }
            alt="bg image"
            className={`${styles.backgroundImg} ${styles.desktopImage}`}
          />
          {predicto && (
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557375_650c7e6f8a.webp"
              alt="mobile bg image"
              className={`${styles.backgroundImg} ${styles.mobileImage}`}
            />
          )}
        </div>
      </Container>
      <img
        className={styles.bannerCurve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
        alt="curve"
      />
    </div>
  )
}

export default BannerSlider
