import React from "react"
import * as styles from "./Result.module.scss"
import { Container, Row, Col } from "react-bootstrap"

function Enhancements({ strapiData, atomic, columnSize, weapon }) {
  return (
    <div
      className={`${styles.enhancements} ${atomic ? styles.atomicEnhac : ""} ${
        weapon ? styles.weaponEnhac : ""
      }`}
    >
      <Container>
        <h2
          className={`${styles.heading} ${atomic ? styles.atomicheading : ""}`}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        ></h2>
        <p
          className={styles.dec}
          dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
        ></p>
        <Row className="justify-content-center">
          {strapiData?.cards?.map((e, i) => (
            <Col lg={columnSize || 6} className="d-flex p-0">
              <div
                className={`${styles.cards} ${weapon ? styles.cardsBg : ""}`}
              >
                <span>0{i + 1}</span>
                <h3
                  dangerouslySetInnerHTML={{
                    __html: e?.title,
                  }}
                ></h3>

                <p
                  className={styles.mainDes}
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Enhancements
