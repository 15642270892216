import React from "react"
import * as styles from "./Enhancements.module.scss"
import { Container, Row, Col } from "react-bootstrap"

function Enhancements({ strapiData, weapon }) {
  return (
    <div
      className={`${styles.enhancements} ${
        weapon ? styles.weaponEnhancements : ""
      }`}
    >
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }}></h2>
        <Row>
          {strapiData?.cards?.map((e, i) => (
            <Col lg={4} md={6} mx={12} className="d-flex">
              <div
                className={`${styles.cards} ${weapon ? styles.weaponCard : ""}`}
              >
                <img src={e?.image1[0]?.localFile?.publicURL} alt="tick" />
                <h3
                  dangerouslySetInnerHTML={{
                    __html: e?.title,
                  }}
                ></h3>

                <p
                  className={styles.mainDes}
                  dangerouslySetInnerHTML={{
                    __html: e?.description?.description,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Enhancements
