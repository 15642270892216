import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Conclusion.module.scss"

function Conclusion({ strapiData, ocr, kyc }) {
  return (
    <div
      className={`${styles.conclusionDiv} ${
        ocr ? styles.ocrConclusionDiv : ""
      } ${kyc ? styles.kycConclusionDiv : ""}`}
    >
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <h2
              className={styles.conclusionHeading}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <p
              className={styles.conclusionText}
              dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
            />
          </Col>
        </Row>
        <img
          className={`${styles.conImg} ${ocr ? styles.ocrImpact : ""}`}
          src="https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557106_01e908f7a0.svg"
        />
      </Container>
    </div>
  )
}

export default Conclusion
