import React from "react"
import * as styles from "./Efficiency.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"

function Enhancements({ strapiData }) {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.enhancements}>
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.secImages[0]?.alternativeText}
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.url}
                alt={strapiData?.secImages[0]?.alternativeText}
                decoding="async"
                loading="lazy"
              />
            )}
          </Col>

          <Col lg={6} >
            {strapiData?.cards?.map((e, i) => (
              <div className={styles.cards}>
                <h3
                  className={styles.blueDot}
                  dangerouslySetInnerHTML={{
                    __html: e?.title,
                  }}
                ></h3>

                <p
                  className={styles.mainDes}
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Enhancements
