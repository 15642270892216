import React from "react"
import * as styles from "./Introsection.module.scss"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Container } from "react-bootstrap"
// import Screen from "../../images/invocom/image.webp"
const AIStatisticsTrend = ({ strapiData, predicto, cheer, easyFill }) => {
  return (
    <div>
      <div className={styles.sectionContainer}>
        <Container>
          <h2
            className={styles.headingIntro}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <Row >
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={5}
              className={styles.headingContainer}
            >
              <p
                className={styles.descriptionText}
                dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
              />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={7}
              className={styles.headingContainer}
            >
              <p
                className={styles.paragraphAi}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </Col>
          </Row>
          <img
            // src={Screen}
            src={
              easyFill
                ? "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_7_23d41932bb.webp"
                : cheer
                ? "https://invozone-backend.s3.us-east-1.amazonaws.com/image_5_4f768fe88a.webp"
                : predicto
                ? "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1686557150_3c3cd9ba61.svg"
                : "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_6_1858e9dfe9.webp"
            }
            alt="laptop"
            className={styles.lapImg}
          />
        </Container>
      </div>
    </div>
  )
}

export default AIStatisticsTrend
