import React from "react"
import * as styles from "./Technologiesweuse.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Col } from "react-bootstrap"

const TrustInvoZone = ({ strapiData }) => {
  return (
    <div className={styles.Trust}>
      <div className={styles.redline}>
        <img
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
          }
          className="w-100 h-100"
          alt="InvoGames"
        />
      </div>
      <Container>
        <Row>
          {strapiData?.cards?.map((e, i) => (
            <Col
              xl={i === strapiData.cards.length - 1 ? 6 : 3}
              md={i === 2 ? 6 : 3}
              xs={12}
            >
              <p
                className={styles.firstHeading}
                dangerouslySetInnerHTML={{
                  __html: e?.title,
                }}
              ></p>
              <span
                className={styles.firstSpan}
                dangerouslySetInnerHTML={{
                  __html: e?.subTitle,
                }}
              ></span>
              <div className={styles.skills}>
                {e?.jsonData?.caseServices?.invocom_caseStudy?.skills?.map(
                  (skill, index) => (
                    <p className={styles.skillHeading}>{skill}</p>
                  )
                )}
              </div>
            </Col>
          ))}
        </Row>
        <hr className={styles.line} />
      </Container>
    </div>
  )
}

export default TrustInvoZone
