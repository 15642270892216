// extracted by mini-css-extract-plugin
export var bets = "KeyFeatures-module--bets--e2b14";
export var card = "KeyFeatures-module--card--c036c";
export var cardHead = "KeyFeatures-module--cardHead--29b1e";
export var colPad = "KeyFeatures-module--colPad--73511";
export var description = "KeyFeatures-module--description--e1ed2";
export var easyfillImg = "KeyFeatures-module--easyfillImg--dbb95";
export var heading = "KeyFeatures-module--heading--45f55";
export var laptopContainer = "KeyFeatures-module--laptopContainer--e7e29";
export var mainDes = "KeyFeatures-module--mainDes--72aa0";
export var ocrBets = "KeyFeatures-module--ocrBets--64bda";
export var ocrCard = "KeyFeatures-module--ocrCard--76e14";
export var ocrCardHead = "KeyFeatures-module--ocrCardHead--cea2a";
export var ocrlaptopContainer = "KeyFeatures-module--ocrlaptopContainer--f969a";
export var ocrmainDec = "KeyFeatures-module--ocrmainDec--82cd6";